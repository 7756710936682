import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { StaticImage } from "gatsby-plugin-image";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import WhiteButton from "../components/v2/WhightButton";
import SEO from "../components/SEO";

import useWidth from "../hooks/useWidth";

import { Link } from "gatsby";

import bannerBackground from "../images/v2/case-studies/banner-background.svg";
import caseStudiesBackground from "../images/v2/case-studies/caseStudiesBackground.svg";
import requestDemoBGImg from "../images/v2/case-studies/requestDemo.svg";
import caseStudyHero from "../images/v2/case-studies/CaseStudyHero.png";
import case1 from "../images/v2/case-studies/case-1.png";
import case2 from "../images/v2/case-studies/case-2.png";
import case3 from "../images/v2/case-studies/case-3.png";
import case4 from "../images/v2/case-studies/case-4.png";
import case5 from "../images/v2/case-studies/case-5.png";
import case6 from "../images/v2/case-studies/case-6.png";
import companyName1 from "../images/v2/case-studies/case-1-name.png";
import companyName2 from "../images/v2/case-studies/case-2-name.png";
import companyName3 from "../images/v2/case-studies/case-3-name.png";
import companyName4 from "../images/v2/case-studies/case-4-name.png";
import companyName5 from "../images/v2/case-studies/case-5-name.png";
import companyName6 from "../images/v2/case-studies/case-6-name.png";

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  bannerBackground: {
    background: `url(${bannerBackground}) left center no-repeat`,
    backgroundSize: "cover",
  },
  caseStudiesBackground: {
    background: `url(${caseStudiesBackground}) left center no-repeat`,
    backgroundSize: "cover",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  readMoreLink: {
    color: "#ff7a59",
    textDecoration: "underline",
    fontSize: "16px",
    padding: "16px 8px",
  },
  getStartedBtn: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  accordionTitle: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

}));

const CaseStudies = () => {
  const classes = useStyles();
  const width = useWidth();

  const caseStudies = [
    {
      id: 1,
      image: case1,
      alt: "Pepper Cloud CRM + Eng Choon Enterprise",
      name: (
        <img
          className={classes.companyImage}
          alt="Pepper Cloud CRM + Eng Choon Enterprise"
          // fit="cover"
          // width="auto"
          height={70}
          placeholder="blurred"
          layout="constrained"
          src={companyName1}
        />
      ),
      description:
        "With WhatsApp-integrated Pepper Cloud CRM, Eng Choon Enterprises is managing its customers’ queries efficiently on various social media platforms.",
      link: "eng-choon-enterprise",
    },
    {
      id: 2,
      image: case2,
      alt: "Pepper Cloud CRM + The Supreme HR Advisory",
      name: (
        <img
          className={classes.companyImage}
          alt="Pepper Cloud CRM + The Supreme HR Advisory"
          fit="cover"
          height={75}
          layout="constrained"
          placeholder="blurred"
          src={companyName2}
          width={236}
        />
      ),
      description:
        "Pepper Cloud CRM is helping The Supreme in organising its sales, boosting its sales productivity, and helping the business grow.",
      link: "the-supreme",
    },
    {
      id: 3,
      image: case3,
      alt: "Pepper Cloud CRM + Dreamcation",

      name: (
        <img
          className={classes.companyImage}
          alt="Pepper Cloud CRM + Dreamcation"
          fit="cover"
          height={52}
          layout="constrained"
          placeholder="blurred"
          src={companyName3}
          width={208}
        />
      ),
      description:
        "Dreamcation, with the help of Pepper Cloud CRM, is improving its response time by replying to its leads and customers quickly.",
      link: "dreamcation",
    },
    {
      id: 4,
      image: case4,
      alt: "Pepper Cloud CRM + SKYPAC",
      name: (
        <img
          className={classes.companyImage}
          alt="Pepper Cloud CRM + SKYPAC"
          fit="cover"
          height={60}
          layout="constrained"
          placeholder="blurred"
          src={companyName4}
          width={156}
        />
      ),
      description:
        "Skypac found an easy-to-use, flexible, customisable, and scalable sales solution with Pepper Cloud CRM.",
      link: "skypac-packaging",
    },
    {
      id: 5,
      image: case5,
      alt: "Pepper Cloud CRM + Tech Data",
      name: (
        <img
          className={classes.companyImage}
          alt="Pepper Cloud CRM + Tech Data"
          fit="cover"
          height={50}
          layout="constrained"
          placeholder="blurred"
          src={companyName5}
          width={228}
        />
      ),
      description:
        "Pepper Cloud CRM is assisting Tech Data in enhancing its productivity through automatic lead capture, customised business modules, and more.",
      link: "tech-data",
    },
    {
      id: 6,
      image: case6,
      alt: "Pepper Cloud CRM + Cuscapi ",
      name: (
        <img
          className={classes.companyImage}
          alt="Pepper Cloud CRM + Cuscapi "
          fit="cover"
          height={50}
          layout="constrained"
          placeholder="blurred"
          src={companyName6}
          width={214}
        />
      ),
      description:
        "Pepper Cloud CRM is providing a one-stop solution for sales pipeline management and user management of Cuscapi.",
      link: "cuscapi",
    },
  ];

  return (
    <React.Fragment>
      <SEO
        canonical="/crm-case-studies"
        description="Pepper Cloud CRM's customer journey case studies reveal how many small-medium businesses and enterprises are leveraging CRM software to drive sales growth. Read all case studies."
        keywords="CRM Case Studies,customer journey case study,CRM Case Studies"
        pathname="/crm-case-studies"
        title="Customer Case Studies | Pepper Cloud CRM"
      />
      <Grid
        alignItems="center"
        className={classes.bannerBackground}
        container
        justify="center"
      >
        <Grid item md={6} sm={12}>
          <Box display="flex" justifyContent={{ sm: "center", md: "flex-end" }}>
            <Box maxWidth={620} p={1}>
              <HeaderTypography
                alignItems="center"
                className={classes.accordionTitle}
                component="h1"
                display="flex"
                fontSize={40}
                fontWeight={700}
                justifyContent={{ sm: "center", md: "flex-start" }}
                lineHeight={1.4}
                mb={2}
                mt={0}
              >
                Customer Case Studies
              </HeaderTypography>
              <ParagraphTypography
                component="p"
                mt={0}
                mb={6}
                pr={{ md: 4 }}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Learn the success stories of businesses from a variety of
                industries and how they have boosted their sales growth with
                Singapore’s #1 sales CRM.
              </ParagraphTypography>
              <Box className={classes.getStartedBtn} mb={6}>
                <Button
                  color="secondary"
                  component={Link}
                  size="large"
                  to="/crm-testimonials"
                  variant="contained"
                >
                  Know More
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box py={{ md: 4 }} textAlign="right">
            <img
              alt="Pepper Cloud CRM's Customer Case Studies"
              className={classes.img}
              src={caseStudyHero}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        alignItems="center"
        className={classes.caseStudiesBackground}
        component={Grid}
        container
        flexDirection={"column"}
        justify="center"
      >
        <Grid item>
          <Box maxWidth={800} pb={3} textAlign="center">
            <HeaderTypography component="h2" fontSize={40} mb={0}>
              Success stories of scaling businesses
            </HeaderTypography>
            <ParagraphTypography fontSize={18} px={{ md: 4 }}>
              Markets are advancing rapidly and consumers are digitising
              quickly. The digitalisation of the business and the sales process
              is cardinal for SMEs.
            </ParagraphTypography>
            <ParagraphTypography fontSize={18} pb={2} px={{ md: 4 }}>
              Here are a few stories of our customers on how they adapted to the
              changing market and embraced unprecedented sales success with
              Pepper Cloud CRM.
            </ParagraphTypography>
          </Box>
        </Grid>
        <Box component={Grid} container maxWidth={1000} py={8} spacing={4}>
          {caseStudies.map((item) => (
            <Grid item key={item.name} md={6} sm={12}>
              <Card sx={{ maxWidth: 434 }}>
                <CardMedia
                  alt={item.alt}
                  component="img"
                  height="271"
                  image={item.image}
                />
                <CardContent>
                  <Box style={{textAlign:'left'}}>{item.name}</Box>
                  <ParagraphTypography
                    component="h2"
                    fontSize={18}
                    mt={2}
                    textAlign={{ sm: "center", xs: "center" }[width] || "left"}
                  >
                    {item.description}
                  </ParagraphTypography>
                </CardContent>
                <CardActions>
                  <Link className={classes.readMoreLink} to={item.link}>
                    Read Case Study
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Box>
      </Box>
      <Box
        className={classes.requestDemoRoot}
        px={2}
        py={{ sm: 10, xs: 8 }[width] || 12}
      >
        <HeaderTypography
          color="common.white"
          fontSize={35}
          m={0}
          textAlign="center"
          component="h2"
        >
          Let’s build your sales success together!
        </HeaderTypography>
        <ParagraphTypography
          color="common.white"
          fontSize={24}
          mt={0}
          textAlign="center"
        >
          Discover how you can build your business, boost your sales and win
          your customers with the all-in-one sales CRM.
        </ParagraphTypography>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            variant="contained"
          >
            Get Started
          </WhiteButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CaseStudies;
